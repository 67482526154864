import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react'; // eslint-disable-line no-restricted-imports
import { Hub, Auth } from 'aws-amplify'; // eslint-disable-line no-restricted-imports

import { Alert } from '@/lib/io-kit/Alert';
import { Button, LoadingButton } from '@/lib/io-kit/Button';
import { Form } from '@/lib/io-kit/Form';

import formStyles from './form.module.scss';
import AuthLayout from './AuthLayout';
import { useShowAuthFormAlert } from './useShowAuthFormAlert';

const Fields = {
  Username: { id: 'username', name: 'username' },
  Password: { id: 'password', name: 'password' },
};

type Props = {
  messages: IntlMessages['Auth']['SignInForm'];
};

export default function SignInForm({ messages }: Props) {
  const { route } = useAuthenticator();
  const { submitSignIn, errorMessage, hasValidationErrors, isPending, toResetPassword } = useSignInForm();
  const { showAlert, dismiss, alertType } = useShowAuthFormAlert(errorMessage, hasValidationErrors);
  const { successMessage, clearSuccessMessage } = useSignInPageSuccessMessage({ route, messages });

  return (
    <AuthLayout
      title={messages.title}
      alert={
        <>
          {successMessage ? (
            <Alert
              title={successMessage}
              variant="success"
              onDismiss={clearSuccessMessage}
              data-testid="auth.sign-in.success"
            />
          ) : null}

          {showAlert ? (
            <Alert data-testid="auth.sign-in.error" title={errorMessage} variant={alertType} onDismiss={dismiss} />
          ) : null}
        </>
      }
    >
      <form className={formStyles.authForm} method="post" onSubmit={submitSignIn}>
        <div className={formStyles.authFormFields}>
          <Form.Group className={formStyles.authFormGroup}>
            <Form.Label htmlFor={Fields.Username.id}>{messages.emailLabel}</Form.Label>
            <Form.Input
              {...Fields.Username}
              data-testid="auth.sign-in.email"
              type="email"
              placeholder={messages.emailPlaceholder}
              inputMode="email"
              autoComplete="email"
              required
            />
          </Form.Group>

          <Form.Group className={formStyles.authFormGroup}>
            <Form.Label htmlFor={Fields.Password.id}>{messages.passwordLabel}</Form.Label>
            <Form.Input
              {...Fields.Password}
              data-testid="auth.sign-in.password"
              type="password"
              placeholder={messages.passwordPlaceholder}
              autoComplete="current-password"
              required
            />
          </Form.Group>
        </div>

        <LoadingButton
          data-testid="auth.sign-in.submit"
          variant="dark"
          type="submit"
          fullWidth
          loading={isPending}
          loadingText={messages.signInLoading}
        >
          {messages.signIn}
        </LoadingButton>
      </form>

      <footer className={formStyles.authFormFooter}>
        <Button as="button" data-testid="auth.sign-in.reset-password" variant="link" onClick={toResetPassword}>
          {messages.forgotPassword}
        </Button>
      </footer>
    </AuthLayout>
  );
}

function useSignInForm() {
  const { submitForm, error, validationErrors, hasValidationErrors, isPending, toResetPassword } = useAuthenticator();

  const submitSignIn = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const target = e.currentTarget;

      // Check whether there is a user already logged in in the same browser
      // Auth.currentSession throws error when checking against no user
      try {
        const currentSession = await Auth.currentSession();
        if (currentSession) {
          return location.reload();
        }
      } catch (err) {
        // No other user logged in from the same browser
        console.log(err);
      }

      submitForm({
        type: 'signIn',
        [Fields.Username.name]: target[Fields.Username.name]?.value,
        [Fields.Password.name]: target[Fields.Password.name]?.value,
      });
    },
    [submitForm],
  );

  const firstValidationError = hasValidationErrors ? (Object.values(validationErrors)[0] as string) : null;
  const errorMessage = firstValidationError ?? error;

  return { submitSignIn, hasValidationErrors, errorMessage, isPending, toResetPassword };
}

type AuthRoute = ReturnType<typeof useAuthenticator>['route'];

/**
 * Handles the success alert message for the Sign-in page.
 * There must be a success message displayed after the user resets their password. In order to achieve this,
 * We need to listen to the Auth events broadcast by Amplify.
 */
function useSignInPageSuccessMessage({
  route,
  messages: { passwordChangeSuccess },
}: {
  route: AuthRoute;
  messages: { passwordChangeSuccess: string };
}) {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [previousRoute, setPreviousRoute] = useState(route);

  const hubListener = useCallback(
    (data: { payload: { event: string } }) => {
      const { payload } = data;

      switch (payload.event) {
        // Event for password reset
        case 'forgotPasswordSubmit': {
          setSuccessMessage(passwordChangeSuccess);
        }
      }
    },
    [passwordChangeSuccess],
  );

  // The listener is set up for the Hub events only for this page.
  useEffect(() => {
    const unsubscribe = Hub.listen('auth', hubListener);
    return () => unsubscribe();
  }, [hubListener]);

  const clearSuccessMessage = useCallback(() => setSuccessMessage(null), []);

  // Clear success message after departing from the Sign-in route. This is done to avoid having the message reappear
  // After the user navigates back to Sign-in.
  if (previousRoute !== route) {
    if (previousRoute === 'signIn') {
      clearSuccessMessage();
    }
    setPreviousRoute(route);
  }

  return { successMessage, clearSuccessMessage };
}
