import React, { FormEvent, useCallback, useState } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react'; // eslint-disable-line no-restricted-imports

import { Alert } from '@/lib/io-kit/Alert';
import { Button, LoadingButton } from '@/lib/io-kit/Button';
import { Form } from '@/lib/io-kit/Form';

import formStyles from './form.module.scss';
import AuthLayout from './AuthLayout';
import { useShowAuthFormAlert } from './useShowAuthFormAlert';

type Props = {
  messages: IntlMessages['Auth']['ConfirmResetPasswordForm'];
};

const Fields = {
  Code: { id: 'confirmation_code', name: 'confirmation_code' },
  Password: { id: 'password', name: 'password' },
  ConfirmPassword: { id: 'confirm_password', name: 'confirm_password' },
};

export default function ConfirmResetPasswordForm({ messages }: Props) {
  const {
    submitConfirm,
    resendCode,
    errorMessage,
    hasValidationErrors,
    isPendingResend,
    isPendingConfirm,
    toSignIn,
    resendStatus,
    resetResendStatus,
  } = useConfirmResetPassword({ passwordTooShortMessage: messages.passwordTooShort });

  const { showAlert, dismiss, alertType } = useShowAuthFormAlert(errorMessage, hasValidationErrors);

  return (
    <AuthLayout
      title={messages.title}
      description={messages.description}
      alert={
        <>
          {resendStatus === 'success' ? (
            <Alert
              title={messages.sendCodeSuccess}
              variant="success"
              onDismiss={resetResendStatus}
              data-testid="auth.reset-password-confirm.success"
            />
          ) : null}

          {showAlert ? (
            <Alert
              data-testid="auth.reset-password-confirm.error"
              title={errorMessage}
              variant={alertType}
              onDismiss={dismiss}
            />
          ) : null}
        </>
      }
    >
      <form className={formStyles.authForm} method="post" onSubmit={submitConfirm}>
        <div className={formStyles.authFormFields}>
          <Form.Group className={formStyles.authFormGroup}>
            <Form.Label htmlFor={Fields.Code.id}>{messages.codeLabel}</Form.Label>
            <Form.Input
              {...Fields.Code}
              data-testid="auth.reset-password-confirm.code"
              type="text"
              placeholder={messages.codePlaceholder}
              inputMode="numeric"
              pattern="[0-9]*"
              autoComplete="one-time-code"
              required
            />
          </Form.Group>

          <Form.Group className={formStyles.authFormGroup}>
            <Form.Label htmlFor={Fields.Password.id}>{messages.newPasswordLabel}</Form.Label>
            <Form.Input
              {...Fields.Password}
              data-testid="auth.reset-password-confirm.password"
              type="password"
              placeholder={messages.newPasswordPlaceholder}
              autoComplete="new-password"
              required
            />
          </Form.Group>

          <Form.Group className={formStyles.authFormGroup}>
            <Form.Label htmlFor={Fields.ConfirmPassword.id}>{messages.confirmPasswordLabel}</Form.Label>
            <Form.Input
              {...Fields.ConfirmPassword}
              data-testid="auth.reset-password-confirm.password-confirm"
              type="password"
              placeholder={messages.confirmPasswordPlaceholder}
              autoComplete="new-password"
              required
            />
          </Form.Group>
        </div>

        <LoadingButton
          data-testid="auth.reset-password-confirm.submit"
          variant="dark"
          type="submit"
          fullWidth
          loading={isPendingConfirm}
          loadingText={messages.submitLoading}
        >
          {messages.submit}
        </LoadingButton>
      </form>

      <footer className={formStyles.authFormFooter}>
        <LoadingButton
          data-testid="auth.reset-password-confirm.resend-code"
          variant="light"
          fullWidth
          onClick={resendCode}
          disabled={isPendingConfirm}
          loading={isPendingResend}
          loadingText={messages.resendCodeLoading}
        >
          {messages.resendCode}
        </LoadingButton>

        <Button
          as="button"
          data-testid="auth.reset-password-confirm.sign-in"
          variant="link"
          onClick={toSignIn}
          disabled={isPendingResend || isPendingConfirm}
        >
          {messages.backToSignIn}
        </Button>
      </footer>
    </AuthLayout>
  );
}

export function useConfirmResetPassword({ passwordTooShortMessage }: { passwordTooShortMessage: string }) {
  const { submitForm, resendCode, error, validationErrors, hasValidationErrors, isPending, toSignIn } =
    useAuthenticator();

  /**
   * We need to track the calls for both submitForm and resend code, in order to properly determine which
   * button to disable and if we need to show a success message
   */
  const [previousIsPending, setPreviousIsPending] = useState(isPending);
  const [resendStatus, setResendStatus] = useState<'idle' | 'pending' | 'success' | 'failed'>('idle');
  // Update pending state
  if (previousIsPending !== isPending) {
    if (!isPending && resendStatus === 'pending') {
      setResendStatus(error ? 'failed' : 'success');
    }
    setPreviousIsPending(isPending);
  }

  const resetResendStatus = useCallback(() => setResendStatus('idle'), []);

  /**
   * Submit the reset form
   */
  const submitConfirm = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      // Noop if a request is in flight
      if (isPending) return;

      setResendStatus('idle');

      const target = e.currentTarget;

      submitForm({
        [Fields.Code.name]: target[Fields.Code.name]?.value,
        [Fields.Password.name]: target[Fields.Password.name]?.value,
        [Fields.ConfirmPassword.name]: target[Fields.ConfirmPassword.name]?.value,
      });
    },
    [isPending, submitForm],
  );

  /**
   * Resend the confirmation code to the user
   */
  const submitResendCode = useCallback(() => {
    // Noop if a request is in flight
    if (isPending) return;

    setResendStatus('pending');
    resendCode();
  }, [isPending, resendCode]);

  // Derived loading state
  const isPendingResend = isPending && resendStatus === 'pending';
  const isPendingConfirm = isPending && resendStatus !== 'pending';

  // Derive error messages
  const firstValidationError = hasValidationErrors ? (Object.values(validationErrors)[0] as string) : null;

  let errorMessage = firstValidationError ?? error;

  // If password is too short, it sends an error with a regex message (See CALLISTO-22). Need to handle it
  if (errorMessage && errorMessage.includes('regular expression')) {
    errorMessage = passwordTooShortMessage;
  }

  return {
    submitConfirm,
    resendCode: submitResendCode,
    errorMessage,
    hasValidationErrors,
    isPendingResend,
    isPendingConfirm,
    toSignIn,
    resendStatus,
    resetResendStatus,
  };
}
