import React, { FormEvent, useCallback, useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react'; // eslint-disable-line no-restricted-imports

import { Alert } from '@/lib/io-kit/Alert';
import { Button, LoadingButton } from '@/lib/io-kit/Button';
import { Form } from '@/lib/io-kit/Form';

import formStyles from './form.module.scss';
import AuthLayout from './AuthLayout';
import { useShowAuthFormAlert } from './useShowAuthFormAlert';

type Props = {
  messages: IntlMessages['Auth']['VerifyUserForm'];
};

const Fields = {
  // This attribute name makes no sense, but here it is
  // @see {@link https://github.com/aws-amplify/amplify-ui/blob/72d5e06ebbf024735ec44c04ad6e61b62a7dd20a/packages/react/src/components/Authenticator/VerifyUser/VerifyUser.tsx#L53}
  UnverifiedAttr: { id: 'unverifiedAttr', name: 'unverifiedAttr' },
};

export default function VerifyUserForm({ messages }: Props) {
  const { submitVerify, email, errorMessage, hasValidationErrors, isPending, skipVerification } =
    useVerifyUserPassword();

  const { showAlert, dismiss, alertType } = useShowAuthFormAlert(errorMessage, hasValidationErrors);

  return (
    <AuthLayout
      title={messages.title}
      description={messages.description}
      alert={
        showAlert ? (
          <Alert title={errorMessage} variant={alertType} onDismiss={dismiss} data-testid="auth.verify-user.error" />
        ) : null
      }
    >
      <form className={formStyles.authForm} method="post" onSubmit={submitVerify}>
        <div className={formStyles.authFormFields}>
          <Form.Group className={formStyles.authFormGroup}>
            <Form.Label htmlFor={Fields.UnverifiedAttr.id}>{messages.emailLabel}</Form.Label>
            <Form.Input
              {...Fields.UnverifiedAttr}
              type="email"
              value={maskEmail(email as string)}
              disabled
              data-testid="auth.verify-user.email"
              inputMode="email"
              autoComplete="email"
            />
          </Form.Group>
        </div>

        <LoadingButton
          variant="dark"
          type="submit"
          fullWidth
          loading={isPending}
          loadingText={messages.submitLoading}
          data-testid="auth.verify-user.submit"
        >
          {messages.submit}
        </LoadingButton>
      </form>

      <footer className={formStyles.authFormFooter}>
        <Button as="button" variant="link" onClick={skipVerification} data-testid="auth.verify-user.skip">
          {messages.skip}
        </Button>
      </footer>
    </AuthLayout>
  );
}

export function useVerifyUserPassword() {
  const {
    submitForm,
    skipVerification,
    error,
    validationErrors,
    hasValidationErrors,
    isPending,
    unverifiedContactMethods,
  } = useAuthenticator();

  const submitVerify = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      submitForm({
        type: 'verifyUser',
        [Fields.UnverifiedAttr.name]: 'email',
      });
    },
    [submitForm],
  );

  const firstValidationError = hasValidationErrors ? (Object.values(validationErrors)[0] as string) : null;
  const errorMessage = firstValidationError ?? error;

  const email = unverifiedContactMethods['email'];

  useEffect(() => {
    // If no email is provided just skip
    if (!email || !unverifiedContactMethods) {
      skipVerification();
    }
  }, [email, skipVerification, unverifiedContactMethods]);

  return { submitVerify, skipVerification, errorMessage, hasValidationErrors, isPending, email };
}

function maskEmail(email: string = '') {
  let str = email.split(''); // eslint-disable-line unicorn/prefer-spread
  let len = str.indexOf('@');
  return str.map((_item, pos) => (pos >= 1 && pos <= len - 2 ? '*' : str[pos])).join('');
}
