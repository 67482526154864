import React from 'react';
import Image from 'next/image';

import { Heading } from '@/lib/io-kit/Heading';

import styles from './AuthLayout.module.scss';

type Props = {
  children: React.ReactNode;
  title: string;
  description?: string;
  alert?: React.ReactNode;
};

export default function AuthLayout({ children, title, description, alert }: Props) {
  return (
    <div className={styles.authLayout}>
      <AuthLayoutHeading title={title} description={description} />
      {alert ? <div className={styles.alertContainer}>{alert}</div> : null}
      <div>{children}</div>
    </div>
  );
}

function AuthLayoutHeading({ title, description }: { title: string; description?: string }) {
  return (
    <header className={styles.headingContainer}>
      <div className={styles.logo}>
        <Image src="/logo-iofinnet-horizontal.svg" alt="" fill />
      </div>

      <Heading as="h1" variant="heading2">
        {title}
      </Heading>

      {description ? <p className={styles.description}>{description}</p> : null}
    </header>
  );
}
