import React, { FormEvent, useCallback } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react'; // eslint-disable-line no-restricted-imports

import { Alert } from '@/lib/io-kit/Alert';
import { Button, LoadingButton } from '@/lib/io-kit/Button';
import { Form } from '@/lib/io-kit/Form';

import formStyles from './form.module.scss';
import AuthLayout from './AuthLayout';
import { useShowAuthFormAlert } from './useShowAuthFormAlert';

type Props = {
  messages: IntlMessages['Auth']['ConfirmSignInForm'];
};

const Fields = {
  Code: { id: 'confirmation_code', name: 'confirmation_code' },
};

export default function ConfirmSignInForm({ messages }: Props) {
  const { submitConfirm, errorMessage, hasValidationErrors, isPending, toSignIn } = useVerifyUserPassword();

  const { showAlert, dismiss, alertType } = useShowAuthFormAlert(errorMessage, hasValidationErrors);

  return (
    <AuthLayout
      title={messages.title}
      description={messages.description}
      alert={
        showAlert ? (
          <Alert
            title={errorMessage}
            variant={alertType}
            onDismiss={dismiss}
            data-testid="auth.confirm-sign-in.error"
          />
        ) : null
      }
    >
      <form className={formStyles.authForm} method="post" onSubmit={submitConfirm}>
        <div className={formStyles.authFormFields}>
          <Form.Group className={formStyles.authFormGroup}>
            <Form.Label htmlFor={Fields.Code.id}>{messages.codeLabel}</Form.Label>
            <Form.Input
              {...Fields.Code}
              type="text"
              placeholder={messages.codePlaceholder}
              data-testid="auth.confirm-sign-in.code"
              inputMode="numeric"
              pattern="[0-9]*"
              autoComplete="one-time-code"
            />
          </Form.Group>
        </div>

        <LoadingButton
          variant="dark"
          type="submit"
          fullWidth
          loading={isPending}
          loadingText={messages.submitLoading}
          data-testid="auth.confirm-sign-in.submit"
        >
          {messages.submit}
        </LoadingButton>
      </form>

      <footer className={formStyles.authFormFooter}>
        <Button as="button" variant="link" onClick={toSignIn} data-testid="auth.confirm-sign-in.sign-in">
          {messages.backToSignIn}
        </Button>
      </footer>
    </AuthLayout>
  );
}

export function useVerifyUserPassword() {
  const { submitForm, toSignIn, error, validationErrors, hasValidationErrors, isPending } = useAuthenticator();

  const submitConfirm = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const target = e.currentTarget;

      submitForm({
        [Fields.Code.name]: target[Fields.Code.name]?.value,
      });
    },
    [submitForm],
  );

  const firstValidationError = hasValidationErrors ? (Object.values(validationErrors)[0] as string) : null;
  const errorMessage = firstValidationError ?? error;

  return { submitConfirm, toSignIn, errorMessage, hasValidationErrors, isPending };
}
