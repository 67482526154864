'use client';

import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react'; // eslint-disable-line no-restricted-imports
import { useEffect } from 'react';

import SignInForm from './SignInForm';
import ResetPasswordForm from './ResetPasswordForm';
import ConfirmResetPasswordForm from './ConfirmResetPasswordForm';
import VerifyUserForm from './VerifyUserForm';
import ConfirmVerifyUserForm from './ConfirmVerifyUserForm';
import ConfirmSignInForm from './ConfirmSignInForm';
import ForceNewPasswordForm from './ForceNewPasswordForm';

type Props = {
  messages: {
    SignInForm: React.ComponentPropsWithoutRef<typeof SignInForm>['messages'];
    ResetPasswordForm: React.ComponentPropsWithoutRef<typeof ResetPasswordForm>['messages'];
    ConfirmResetPasswordForm: React.ComponentPropsWithoutRef<typeof ConfirmResetPasswordForm>['messages'];
    VerifyUserForm: React.ComponentPropsWithoutRef<typeof VerifyUserForm>['messages'];
    ConfirmVerifyUserForm: React.ComponentPropsWithoutRef<typeof ConfirmVerifyUserForm>['messages'];
    ConfirmSignInForm: React.ComponentPropsWithoutRef<typeof ConfirmSignInForm>['messages'];
    ForceNewPasswordForm: React.ComponentPropsWithoutRef<typeof ForceNewPasswordForm>['messages'];
  };
};

export function AuthFlow({ messages }: Props) {
  const { route } = useAuthenticator();
  useEffect(() => {
    if (route === 'authenticated') {
      // We do this to avoid the access token being return as `null` right after signing in
      location.href = '/';
    }
  }, [route]);

  switch (route) {
    case 'authenticated': {
      return <>{null}</>;
    }
    case 'signIn': {
      return <SignInForm messages={messages.SignInForm} />;
    }
    case 'resetPassword': {
      return <ResetPasswordForm messages={messages.ResetPasswordForm} />;
    }
    case 'confirmResetPassword': {
      return <ConfirmResetPasswordForm messages={messages.ConfirmResetPasswordForm} />;
    }
    case 'verifyUser': {
      return <VerifyUserForm messages={messages.VerifyUserForm} />;
    }
    case 'confirmVerifyUser': {
      return <ConfirmVerifyUserForm messages={messages.ConfirmVerifyUserForm} />;
    }
    case 'confirmSignIn': {
      return <ConfirmSignInForm messages={messages.ConfirmSignInForm} />;
    }
    case 'forceNewPassword': {
      return <ForceNewPasswordForm messages={messages.ForceNewPasswordForm} />;
    }
    default: {
      return <Authenticator hideSignUp />;
    }
  }
}
