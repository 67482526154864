import React, { FormEvent, useCallback } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react'; // eslint-disable-line no-restricted-imports

import { Alert } from '@/lib/io-kit/Alert';
import { Button, LoadingButton } from '@/lib/io-kit/Button';
import { Form } from '@/lib/io-kit/Form';

import formStyles from './form.module.scss';
import AuthLayout from './AuthLayout';
import { useShowAuthFormAlert } from './useShowAuthFormAlert';

type Props = {
  messages: IntlMessages['Auth']['ForceNewPasswordForm'];
};

const Fields = {
  Password: { id: 'password', name: 'password' },
  ConfirmPassword: { id: 'confirm_password', name: 'confirm_password' },
};

export default function ForceNewPasswordForm({ messages }: Props) {
  const { submitConfirm, toSignIn, errorMessage, hasValidationErrors, isPending } = useConfirmResetPassword();

  const { showAlert, dismiss, alertType } = useShowAuthFormAlert(errorMessage, hasValidationErrors);

  return (
    <AuthLayout
      title={messages.title}
      description={messages.description}
      alert={
        showAlert ? (
          <Alert
            data-testid="auth.force-new-password.error"
            title={errorMessage}
            variant={alertType}
            onDismiss={dismiss}
          />
        ) : null
      }
    >
      <form className={formStyles.authForm} method="post" onSubmit={submitConfirm}>
        <div className={formStyles.authFormFields}>
          <Form.Group className={formStyles.authFormGroup}>
            <Form.Label htmlFor={Fields.Password.id}>{messages.newPasswordLabel}</Form.Label>
            <Form.Input
              {...Fields.Password}
              data-testid="auth.force-new-password.password"
              type="password"
              placeholder={messages.newPasswordPlaceholder}
              autoComplete="new-password"
              required
            />
          </Form.Group>

          <Form.Group className={formStyles.authFormGroup}>
            <Form.Label htmlFor={Fields.ConfirmPassword.id}>{messages.confirmPasswordLabel}</Form.Label>
            <Form.Input
              {...Fields.ConfirmPassword}
              data-testid="auth.force-new-password.password-confirm"
              type="password"
              placeholder={messages.confirmPasswordPlaceholder}
              autoComplete="new-password"
              required
            />
          </Form.Group>
        </div>

        <LoadingButton
          data-testid="auth.force-new-password.submit"
          variant="dark"
          type="submit"
          fullWidth
          loading={isPending}
          loadingText={messages.submitLoading}
        >
          {messages.submit}
        </LoadingButton>
      </form>

      <footer className={formStyles.authFormFooter}>
        <Button as="button" variant="link" onClick={toSignIn}>
          {messages.backToSignIn}
        </Button>
      </footer>
    </AuthLayout>
  );
}

export function useConfirmResetPassword() {
  const { submitForm, toSignIn, error, validationErrors, hasValidationErrors, isPending } = useAuthenticator();

  const submitConfirm = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const target = e.currentTarget;

      submitForm({
        [Fields.Password.name]: target[Fields.Password.name]?.value,
        [Fields.ConfirmPassword.name]: target[Fields.ConfirmPassword.name]?.value,
      });
    },
    [submitForm],
  );

  const firstValidationError = hasValidationErrors ? (Object.values(validationErrors)[0] as string) : null;
  const errorMessage = firstValidationError ?? error;

  return { submitConfirm, toSignIn, errorMessage, hasValidationErrors, isPending };
}
