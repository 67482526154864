import React, { FormEvent, useCallback } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react'; // eslint-disable-line no-restricted-imports

import { Alert } from '@/lib/io-kit/Alert';
import { Button, LoadingButton } from '@/lib/io-kit/Button';
import { Form } from '@/lib/io-kit/Form';

import formStyles from './form.module.scss';
import AuthLayout from './AuthLayout';
import { useShowAuthFormAlert } from './useShowAuthFormAlert';

type Props = {
  messages: IntlMessages['Auth']['ResetPasswordForm'];
};

const Fields = {
  Username: { id: 'username', name: 'username' },
  Password: { id: 'password', name: 'password' },
};

export default function ResetPasswordForm({ messages }: Props) {
  const { submitResetPassword, errorMessage, hasValidationErrors, isPending, toSignIn } = useResetPasswordForm();

  const { showAlert, dismiss, alertType } = useShowAuthFormAlert(errorMessage, hasValidationErrors);

  return (
    <AuthLayout
      title={messages.title}
      description={messages.description}
      alert={
        showAlert ? (
          <Alert data-testid="auth.reset-password.error" title={errorMessage} variant={alertType} onDismiss={dismiss} />
        ) : null
      }
    >
      <form className={formStyles.authForm} method="post" onSubmit={submitResetPassword}>
        <div className={formStyles.authFormFields}>
          <Form.Group className={formStyles.authFormGroup}>
            <Form.Label htmlFor={Fields.Username.id}>{messages.emailLabel}</Form.Label>
            <Form.Input
              {...Fields.Username}
              data-testid="auth.reset-password.email"
              type="email"
              placeholder={messages.emailPlaceholder}
              inputMode="email"
              autoComplete="email"
              required
            />
          </Form.Group>
        </div>

        <LoadingButton
          data-testid="auth.reset-password.send-code"
          variant="dark"
          type="submit"
          fullWidth
          loading={isPending}
          loadingText={messages.sendCodeLoading}
        >
          {messages.sendCode}
        </LoadingButton>
      </form>

      <footer className={formStyles.authFormFooter}>
        <Button as="button" data-testid="auth.reset-password.sign-in" variant="link" onClick={toSignIn}>
          {messages.backToSignIn}
        </Button>
      </footer>
    </AuthLayout>
  );
}

function useResetPasswordForm() {
  const { submitForm, error, validationErrors, hasValidationErrors, isPending, toSignIn } = useAuthenticator();

  const submitResetPassword = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const target = e.currentTarget;

      submitForm({
        type: 'resetPassword',
        [Fields.Username.name]: target[Fields.Username.name]?.value,
      });
    },
    [submitForm],
  );

  const firstValidationError = hasValidationErrors ? (Object.values(validationErrors)[0] as string) : null;
  const errorMessage = firstValidationError ?? error;

  return { submitResetPassword, errorMessage, hasValidationErrors, isPending, toSignIn };
}
